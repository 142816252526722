<template>
  <div class="row">
    <div class="col-md-12">
      <p style="display: none">
        <a
          class="btn btn-success"
          href="javascript:void(0);"
          @click="collapse = collapse"
        >
          Download template surat <i class="fa fa-download"></i>
        </a>
      </p>
      <div v-if="collapse">
        <div class="card card-body">
          <div class="row">
            <CustomMulti
              :label="$t('please_download_template')"
              size="6"
              :query="documentName"
              :list="documentNameList"
              :model="form.document_name"
              @data="setValue('document_name', $event)"
            />
            <div class="col-md-6">
              <label class="control-label">&nbsp;</label>
              <div>
                <a
                  class="btn btn-info"
                  v-if="form.document_name"
                  :href="form.document_name.link_file"
                  target="_blank"
                  ><i class="fa fa-download"></i> Download Template Surat</a
                >
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-12">
              <p>
                Silakan langsung edit pada
                <b style="font-weight: 900">Isi Surat</b>, abaikan bagian
                <b style="font-weight: 900">Pembuka Surat</b> karena data akan
                tergenerete secara otomatis oleh sistem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Daftar Surat Digital</b>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <!-- <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              :onBatal="doBatal"
              :onDetailTL="doDetailTL"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidBatal="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidDetailTL="forbidRead"
              :forbidDelete="true"
            /> -->
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              :onDetailTL="doDetailTL"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidBatal="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidDetailTL="forbidRead"
              :forbidDelete="true"
            />
          </div>
        </div>
        <hr />
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");

    let roles = this.$store.state.profile.user.datarole1;

    let params = {};
    if (last[0].includes("document_in")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
      params = {};
    }

    let cari = this.from;

    let filter = false;
    if (cari == "Pencarian Khusus") {
      filter = true;
      alert(filter);
    }

    return {
      documentNameList: [],
      collapse: true,
      form: {
        document_name: null,
      },
      from: roles,
      search: params.search || "",
      showFilter: filter,
      cari: "",
      start_date: "",
      end_date: "",
      headers: {
        no_opd: {
          label: "Nomor Surat",
          data: "no_opd",
        },
        subject: {
          label: "Perihal",
          data: "subject",
        },
        document_date: {
          label: "Tanggal",
          data: "document_date",
          render: function (data) {
            return new Date(data).toDateString();
          },
        },
        // document_number:{
        //   label:'Nomor Surat',
        //   data:'document_number',
        // },
        penandatangan: {
          label: "Penanda Tangan",
          data: "sender.name",
        },
        drafter: {
          label: "Drafter",
          data: "creator.name",
        },
        // final_approved: {
        //   label: "Status",
        //   data: "final_approvement.name",
        //   render: function (data) {
        //     return _this.$t(data);
        //   },
        // },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.drafting_file.items !== undefined) {
        if (this.$store.state.drafting_file.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.drafting_file.items.items) &&
            this.$store.state.drafting_file.items.items !== undefined
          ) {
            data = Object.keys(this.$store.state.drafting_file.items.items).map(
              (i) => this.$store.state.drafting_file.items.items[i]
            );
          } else {
            data = this.$store.state.drafting_file.items.items;
          }
        }
      }
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.drafting_file.items !== undefined) {
        if (this.$store.state.drafting_file.items.filters !== undefined) {
          filt = this.$store.state.drafting_file.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.drafting_file;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_in_all) {
        return this.$store.state.profile.permissions.document_in_all.read;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidAtasan() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidKonfirmasi() {
      if (this.$store.state.profile.permissions.document_in_confirm) {
        return this.$store.state.profile.permissions.document_in_confirm.create;
      }
      return false;
    },
    forbidDelete() {
      if (this.$store.state.profile.permissions.drafting-file) {
        return !this.$store.state.profile.permissions.drafting-file.delete;
      }
      return false;
    },
    forbidDisposisi() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      from: this.from,
      jabatan: this.$store.state.profile.user.structural_position_id,
      // jabatan: this.$store.state.documentIn.items.atensi,
      start_date: this.start_date,
      end_date: this.end_date,
    };
    this.$store.commit("drafting_file/STATE", state);
    this.get(state);
  },
  methods: {
    setValue(model, data) {
      this.form[model] = data;
    },
    onSearch(val) {
      this.search = val;
    },
    // documentName(query) {
    //   axios.get(`clasification/list-active?s=${query}`).then((res) => {
    //     this.documentNameList = res.data.items;
    //   });
    // },
    documentName(query) {
      axios.get(`clasification/list-surat?s=${query}`).then((res) => {
        this.documentNameList = res.data.items;
      });
    },
    goBack() {
      this.$router.push("/drafting_file");
    },
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("drafting_file/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("drafting_file/getdrafting", val);
    },
    resetInput() {
      this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.cari = "";
      // this.nomor_agenda = "";
      // this.periode_surat = "";
      // this.perihal = "";
      // this.nomor_surat = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("drafting_file/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("drafting_file/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("drafting_file/onDetail", val);
    },
    doDetailTL(val) {
      this.$store.dispatch("drafting_file/onDetailTL", val);
    },
    // doBerkas(val) {
    //   const data = {
    //     id: val.id,
    //     flag_atasan: 1,
    //   };
    //   // const data = JSON.stringify(payload);
    //   this.$store.dispatch("documentIn/submitTandaiAtasan", data);
    // },
    doAtasan(val) {
      const data = {
        id: val.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("drafting_file/submitTandaiAtasanDepan", data);
    },
    doEdit(val) {
      this.$store.dispatch("drafting_file/onEdit", val);
    },
    doKonfirmasi(val) {
      this.$store.dispatch("drafting_file/onKonfirmasi", val);
    },
    // doKonfirmasi(val) {
    //   this.$store.dispatch("documentIn/onConfirm", val);
    // },
    doDisposisi(val) {
      this.$store.dispatch("drafting_file/onDisposisi", val);
    },
    doDelete(val) {
      this.$store.dispatch("drafting_file/submitDelete", val);
    },
    doBatal(val) {
      this.$store.dispatch("drafting_file/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
        // loaded: false,
        // items: [],
        // from: this.from,
        // search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("drafting_file/STATE", state);
      this.get(state);
    },
  },
};
</script>
